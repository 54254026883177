.checklists-show {
  &-gallery {
    position: relative;
    cursor: pointer;

    &:hover {
      button {
        display: block;
      }

      img {
        opacity: 0.5;
      }
    }
  }
}

.gallery-img {
  float: left;
  width: 100%;
  max-width: 100px;
  border: 0 !important;
  padding: 7px;
  margin-right: 40px !important;
  // max-height: 100px;
  img {
      width: 100%;
      min-height: 45px;
  }
}

.img-thumbnail {
  width: 100%;
  // max-height: 100px;
  padding: 0;
  margin: 0 auto;
  display: block;
  border: 0;
}




.btn-delete {
  display: block;
  position: relative;
  z-index: 999;
  top: -4rem;
  right: -4.5rem;
}